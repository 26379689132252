import { useMantineTheme } from '@mantine/core';
import React from 'react';
import { ContestStatus } from '@betterpool/api-types/contests-service';

import dt from '~/testing';

import type { Contest } from '../../../services/interfaces/contest.interface';
import type { MyUserContest } from '../../../myContests/types/userContest';

import useStyles from './ContestCard.styles';
import type { ContestCardProps } from './types';
import CardContainer from './CardContainer';
import ContestCardProgressBar from './ContestCardProgressBar';
import ContestCardHeader from './ContestCardHeader';
import ContestCardFooter from './Footer/ContestCardFooter';
import ContestCardBanner from './ContestCardBanner';
import { ContestCardActions } from './ContestCardActions';

function ContestCard({
  actionBanner,
  contest,
  currentSlateStatus,
  isBadgeIconSectionEnabled = true,
  isBannerEnabled = false,
  isCardMenuVisible = true,
  isContestThumbnailShown,
  isCountdownPillEnabled = false,
  isInvitesManagerEnabled = false,
  isMassCommunicationEnabled = false,
  isMyContestsView = false,
  isNotAllowedInLocation,
  isPlayWithPeopleYouFollowEnabled = false,
  isStatic,
  isSurvivor = false,
  leaderboard,
  onClick,
  promoIndex,
  promoSource,
  survivor,
}: ContestCardProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles({ isStatic });
  const contestCardBg = (() => {
    if (isNotAllowedInLocation) {
      return theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.gray[7];
    }

    return undefined;
  })();

  const isEntertainmentOnly =
    (contest as Contest).settings?.entertainmentOnlySettings?.enabled ||
    (contest as MyUserContest).isEntertainmentOnly;

  return (
    <CardContainer
      p="md"
      shadow={isStatic ? 'none' : 'sm'}
      bg={contestCardBg}
      radius="md"
      isMyContestsView={isMyContestsView}
      className={classes.container}
      data-test-id={dt.common.components.contestCard}
      contest={contest}
      onClick={onClick}
      isStatic={isStatic}
      promoSource={promoSource}
      promoIndex={promoIndex}
      currentSlateStatus={currentSlateStatus}
    >
      <ContestCardBanner
        contest={contest}
        isBannerEnabled={isBannerEnabled}
        isCountdownPillEnabled={isCountdownPillEnabled}
      />
      <ContestCardHeader
        contest={contest}
        isBadgeIconSectionEnabled={isBadgeIconSectionEnabled}
        isCardMenuVisible={isCardMenuVisible}
        isContestThumbnailShown={isContestThumbnailShown}
      />
      <ContestCardProgressBar leaderboard={leaderboard} contest={contest} isSurvivor={isSurvivor} />
      <ContestCardFooter
        winning={leaderboard?.userPayout}
        userSegmentedPayouts={leaderboard?.userSegmentedPayouts}
        entryPrice={contest.entry_fee}
        isSurvivor={isSurvivor}
        contest={contest}
        userAliveEntries={survivor?.userAliveEntries}
        actionBanner={actionBanner}
        isSettled={contest.status === ContestStatus.FINALIZED}
        isMyContestsView={isMyContestsView}
        totalEntries={leaderboard?.totalSpots}
        winningEntries={leaderboard?.payoutSpots}
        rank={leaderboard?.positions[0]?.rank}
        isEntertainmentOnly={isEntertainmentOnly}
      />
      {(isInvitesManagerEnabled ||
        isMassCommunicationEnabled ||
        isPlayWithPeopleYouFollowEnabled) && (
        <ContestCardActions
          contest={contest}
          isInvitesManagerEnabled={isInvitesManagerEnabled}
          isMassCommunicationEnabled={isMassCommunicationEnabled}
          isPlayWithPeopleYouFollowEnabled={isPlayWithPeopleYouFollowEnabled}
        />
      )}
    </CardContainer>
  );
}

export default ContestCard;
